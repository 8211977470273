@import "~bootstrap/dist/css/bootstrap.css";

/* Add padding for the nav bar */
.body {
  background-color: #495057;
}
/* Style debug info in alerts */
.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

html {
  font-size: 16px;
}



